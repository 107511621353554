import React from "react"
import { Link } from "gatsby"
import CarraraLogo from "../images/carrara_logo/carrara-logo_1.svg"
import { useStaticQuery, graphql } from "gatsby"
import {
  FaChevronRight,
  FaFacebook,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa"

export default () => {
  const data = useStaticQuery(graphql`
  {
    blogs: allFile(filter: {relativeDirectory: {eq: "blog"}}, limit: 5) {
      edges {
        node {
          childMarkdownRemark {
            fields {
              slug
            }
          frontmatter {
            title
          }
        }
      }
    }
    }
    courses: allFile(filter: {relativeDirectory: {eq: "dersler"}}) {
      edges {
        node {
          childMarkdownRemark {
            fields {
              slug
            }
          frontmatter {
            title
            isNewContent
          }
        }
      }
    }
    }
  }
  `)
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a className="logo-footer" href="#">
              <img alt='Carrara Logo' style={{ width: '9rem' }} src={CarraraLogo}></img>
            </a>
            <p className="mt-4">
            Tatlısu Mahallesi, Akdağ Caddesi, No: 31/A Ümraniye/İstanbul, 34764 Ümraniye/İstanbul, Türkiye
            </p>
            <ul className="list-unstyled social-icon social mb-0 mt-4">
              <li className="list-inline-item">
                <a href="https://www.facebook.com/carraraspormerkezi" className="rounded">
                  <i className="mdi mdi-facebook" title="Facebook">
                    <FaFacebook />
                  </i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/carrarapilates/" className="rounded">
                  <i className="mdi mdi-instagram" title="Instagram" />
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <Link to="/dersler" >
              <h4 className="text-light footer-head">Dersler</h4>
            </Link>
            <ul className="list-unstyled footer-list mt-4">
              {data.courses.edges.map(({ node }) => (
                <li>
                  <Link
                    className="text-foot"
                    to={node.childMarkdownRemark.fields.slug}
                  >
                    <i className="mdi mdi-chevron-right mr-1">
                      {" "}
                      <FaChevronRight className="mdi mdi-chevron-right mr-1" />
                    </i>
                    {node.childMarkdownRemark.frontmatter.title}
                    {node.childMarkdownRemark.frontmatter.isNewContent ===
                      true ? (
                        <span className="badge badge-success ml-2">Yeni</span>
                      ) : null}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <Link to="/blog" >  <h4 className="text-light footer-head">Blog</h4>      </Link>
            <ul className="list-unstyled footer-list mt-4">
              {data.blogs.edges.map(({ node }) => (
                <li>
                  <Link
                    className="text-foot"
                    to={node.childMarkdownRemark.fields.slug}
                  >
                    <i className="mdi mdi-chevron-right mr-1">
                      {" "}
                      <FaChevronRight className="mdi mdi-chevron-right mr-1" />
                    </i>
                    {node.childMarkdownRemark.frontmatter.title}
                    {node.childMarkdownRemark.frontmatter.isNewContent ===
                      true ? (
                        <span className="badge badge-success ml-2">Yeni</span>
                      ) : null}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container text-center mt-5">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="text-sm-left">
              <p
                className="mb-0"
                style={{
                  fontSize: "13px",
                }}
              >
                © {new Date().getFullYear()}
                <i className="mdi mdi-heart text-light" /> <a className='text-light' href="https://www.monotalic.com/" target="_blank" rel="noopener"> Monotalic </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*end container*/}
    </footer>
  )
}

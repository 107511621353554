import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import React, { useState }  from "react"
import CarraraLogoType from "../images/carrara_logo/carrara-logotype.svg"
export default () => {

  const [value, setValue ] = useState(false);
  const [item, setItem ] = useState(false);
  
  function MenuToggle() {
    if (!value) setValue(true);
    else setValue(false);
  }
  function ItemToggle() {
    if (!item) setItem(true);
    else setItem(false);
  }

  const data = useStaticQuery(graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "dersler"}}, sort: {fields: childMarkdownRemark___frontmatter___order, order: ASC}) {
      edges {
        node {
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
              isNewContent
            }
          }
        }
      }
    }
  }  
  `)
  return (
    <header id="topnav" className="defaultscroll sticky bg-white">
      <div className="container">
        <div>
          <Link className="logo" to="/">
            <img alt='Carrara Logo' style={{width:'11rem'}} src={CarraraLogoType}></img> 
          </Link> 
        </div>
        <div className="menu-extras" onClick={MenuToggle}>
          <div className="menu-item" >
            <a className={`${value ? 'navbar-toggle open' : "navbar-toggle"}`}>
              <div className="lines">
                <span />
                <span />
                <span />
              </div>
            </a>
          </div>
        </div>
        <div  id='#navigation' className={`${value ? 'navigation navopen' : "navigation navclose"}`} >
          <ul className="navigation-menu">
            <li>
              <Link to="/">Ana Sayfa</Link>
            </li>
            <li className="has-submenu active open" onClick={ItemToggle} >
              <a  href={null}>Dersler</a>
              <span className="menu-arrow" />
              <ul className={`${item ? 'submenu active' : "submenu active open"}`}>
                <li>
                  {data.allFile.edges.map(({ node }) => (
                    <ul>
                      <Link to={node.childMarkdownRemark.fields.slug}>
                        {node.childMarkdownRemark.frontmatter.title}
                        {node.childMarkdownRemark.frontmatter.isNewContent ===
                        true ? (
                          <span className="badge badge-success ml-2">Yeni</span>
                        ) : null}
                      </Link>
                    </ul>
                  ))}
                  <ul>
                      <Link to='/dersler'>
                       Tüm Dersler
                      </Link>
                    </ul>
                </li>
              </ul>
            </li>
            <li className="has-submenu">
              <Link to="/egitmenler">Eğitmenler</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/studyo">Stüdyo</Link>
            </li>
            <li>
              <Link to="/hakkimizda">Hakkımızda</Link>
            </li>
            <li>
              <Link to="/iletisim">İletişim</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}
